<template>
  <div class="w-content">
    <form id="FormaPassword" @submit.prevent="submitLoginHanlder">
      <h1>{{$t("SignIn_h1_1")}}</h1>
      <p>{{$t("SignIn_p_1")}}</p>
      <h2>{{$t("SignIn_h2_1")}}</h2>
      <p>{{$t("SignIn_p_2")}}</p>
      <!--<p>{{$t("SignIn_label_1")}}</p>
      <input
        class="main-input"
        id="Public"
        v-model="publicKey"
        v-model.trim="publicKey"
        :placeholder="[[$t('SignIn_input_placeholder_1')]]"
      />-->
      <!--<span class="textColorRed" id="ErrorPub" v-if="!$v.publicKey.required"></span>-->
      <p>{{$t("SignIn_label_2")}}</p>
      <input
        class="main-input"
        id="Private"
        v-model="privateKey"
        v-model.trim="privateKey"
        :placeholder="[[$t('SignIn_input_placeholder_2')]]"
      />
      <span class="textColorRed" id="ErrorPriv" v-if="!$v.privateKey.required"></span>
      <p class="fontBold">
        <button class="bold-link bttn margin_top_20" id type="submit">{{$t("SignIn_button_1")}}</button>
      </p>
      <h2>{{$t("SignIn_h2_2")}}</h2>

      <input type="file" name="file" id="file" class="inputfile" @change="attachFile" />
      <label for="file" id="fileIndicator">
        <span v-if="fileName!=''">{{fileName}}</span>
        <span v-else>Attach file</span>
      </label>
    </form>
  </div>
</template>
<script>
  import {required} from "vuelidate/lib/validators";
  import i18n from "../i18n";

  export default {
  created() {
    //console.log("component was created! LOGIN");
  },
  data() {
    return {
      result: {},
      publicKey: "",
      privateKey: "",
      fileName: ""
    };
  },
  validations: {
    //publicKey: { required },
    privateKey: { required }
  },
  methods: {
    // login() {

    //   axios
    //     .post("api/wallet/auth?publicKey=" + this.publicKey, {
    //       params: { publicKey: this.publicKey }
    //       // headers: { "Content-Type": "application/x-www-form-urlencoded" }
    //     })
    //     .then(x => {
    //       this.result = x.data;
    //       //TODO
    //       console.log("response:");
    //       console.log(x.data);
    //       if (x.data) {
    //         //TODO
    //         let pk = this.publicKey;

    //         this.$router.push({
    //           path: "/wallet/perform-transaction",
    //           params: { pk }
    //         });
    //       }
    //     });
    // },
    submitLoginHanlder() {
      if (this.$v.$invalid === true) {
        console.log("validate error");
        return;
      }
      let a = Base58.decode(this.privateKey);
      if (a.length != 64) {
        alert("Your key length is not valid! Check it before use");
        return;
      }
      this.publicKey = Base58.encode(a.slice(32));
      console.log("PKey = ", this.publicKey);
      this.$store.commit("SET_publicKey", this.publicKey);
      this.$store.commit("SET_privateKey", this.privateKey);

      this.$router.push({
        path: `/${i18n.locale}/wallet/perform-transaction`
      });
    },
    attachFile(e) {
      if (typeof window.FileReader === undefined) {
        alert(
          "FILE API not allowed! Sorry, you might login in your wallet from form fields or regsiter new wallet!"
        );
        return;
      }

      let _vue = this;
      let file = e.target.files[0];

      this.fileName = file.name;
      let ext = /[.]/.exec(this.fileName)
        ? /[^.]+$/.exec(this.fileName)
        : undefined;
      console.log("ex:");
      console.log();
      if (ext[0] != "json") {
        alert("file not supported!");
        return;
      }
      let fileReader = new FileReader();
      fileReader.onload = (function(f) {
        return function(e) {
          try {
            let json = JSON.parse(e.target.result);
            console.log(json);

            _vue.publicKey = json["key"].public;
            _vue.privateKey = json["key"].private;

            if (_vue.publicKey === "" || _vue.privateKey === "") {
              alert("invalid opration!!");
            } else {
              //console.log(_vue.publicKey);
              //console.log(_vue.privateKey);
              _vue.$store.commit("SET_publicKey", _vue.publicKey);
              _vue.$store.commit("SET_privateKey", _vue.privateKey);
              // alert("Success. I redirect to wallet!");
              _vue.$router.push(`/${i18n.locale}/wallet/perform-transaction`);
            }
          } catch (ex) {
            console.log(ex);
          }
        };
      })(file);
      fileReader.readAsText(file);
    }
  },
  computed: {
    // ...mapState({
    //   _publicKey: state => state.publicKey,
    //   _privateKey: state => state.privateKey
    // })
  }
};
</script>
<style scoped>
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  border: 2px solid #13191c;
  padding: 10px 35px;
  border-radius: 7px;
  cursor: pointer;
  font-size: 14px;
  background-color: #13191c;
  color: #fff;
  width: 200px;
  text-align: center;
  transition: 0.3s;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 800;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: rgba(255, 255, 255, 0.102);
  color: black;
}
.inputfile + label {
  cursor: pointer; /* "hand" cursor */
}
.inputfile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
</style>
